import React from "react"
import Layout from "./layout"

const Project = ({ children, className = '' }) => {
  return (
    <Layout className={`project ${className}`}>
        {children}
    </Layout>
  )
}

export default Project
